import React, { useEffect, useRef, useState } from 'react';
import classes from './Navbar.component.module.scss';
import logoBookmark from '../../assets/logoBookmark.svg';
import logoBookmarkWhite from '../../assets/logo-bookmark.svg';

import hamburgerIcon from '../../assets/icon-hamburger.svg';
import closeMenuIcon from '../../assets/icon-close.svg';
import { Link, NavLink } from 'react-router-dom';
import { gsap } from 'gsap';

export const Navbar: React.FC = () => {
    const featuresButtonRef = useRef<HTMLAnchorElement>(null);
    const pricingButtonRef = useRef<HTMLAnchorElement>(null);
    const contactButtonRef = useRef<HTMLAnchorElement>(null);
    const loginButtonRef = useRef<HTMLAnchorElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    const triggerMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    const hamburgerMenuRef = useRef(null);
    useEffect(() => {
        if (!hamburgerMenuRef.current) {
            setMenuIsOpen(false);
        }
    }, [hamburgerMenuRef.current]);

    useEffect(() => {
        if (menuIsOpen) {
            const tl = gsap.timeline();
            tl.fromTo(loginButtonRef.current, { top: '-100vh' }, { top: 0, duration: 0.5, delay: 0.45, ease: 'circ.out' });
            tl.fromTo(contactButtonRef.current, { top: '-100vh' }, { top: 0, duration: 0.5, ease: 'circ.out' });
            tl.fromTo(pricingButtonRef.current, { top: '-100vh' }, { top: 0, duration: 0.5, ease: 'circ.out' });
            tl.fromTo(featuresButtonRef.current, { top: '-100vh' }, { top: 0, duration: 1, ease: 'bounce.out' });

            gsap.fromTo(logoRef.current, { opacity: 0 }, { opacity: 1, delay: 0.45 });
        }
    }, [menuIsOpen]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={classes.navbar}>
            <Link to={'/'} className={classes.navbarLogoWrapper}>
                <img src={logoBookmark} alt="navbar logo" />
            </Link>
            {screenWidth > 710 && (
                <div className={classes.navbarActionsWrapper}>
                    <Link to={'..'} className={classes.link}>
                        FEATURES
                    </Link>
                    {/* <Link to={'..'} className={classes.link}>
                        PRICING
                    </Link> */}
                    <NavLink
                        to={'/contact'}
                        className={({ isActive }) => (isActive ? `${classes.link} ${classes.navItemactive}` : classes.link)}
                    >
                        CONTACT
                    </NavLink>
                    {/* todo language changer */}
                    <Link to={'..'} className={classes.login}>
                        LOGIN
                    </Link>
                </div>
            )}

            {screenWidth <= 710 && (
                <div ref={hamburgerMenuRef}>
                    {!menuIsOpen && (
                        <img onClick={triggerMenu} src={hamburgerIcon} alt="hamburger menu" className={classes.hamburgerMenuTrigger} />
                    )}
                    <div className={`${classes.navbarMenu} ${menuIsOpen ? classes.navbarMenuActive : ''}`}>
                        <img
                            onClick={triggerMenu}
                            src={closeMenuIcon}
                            alt="close menu"
                            className={`${classes.hamburgerMenuTrigger} ${classes.jiggle}`}
                        />

                        <img src={logoBookmarkWhite} alt="logo" className={classes.navbarMenuLogo} ref={logoRef} />
                        <Link to={'..'} className={classes.link} ref={featuresButtonRef}>
                            FEATURES
                        </Link>
                        {/* <Link to={'..'} className={classes.link} ref={pricingButtonRef}>
                            PRICING
                        </Link> */}
                        <NavLink
                            onClick={triggerMenu}
                            to={'/contact'}
                            className={({ isActive }) => (isActive ? `${classes.link} ${classes.navItemactive}` : classes.link)}
                            ref={contactButtonRef}
                        >
                            CONTACT
                        </NavLink>
                        <Link to={'..'} className={classes.link} ref={loginButtonRef}>
                            LOGIN
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};
