import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FullPageLoader from './shared/FullPageSpinner/FullPageSpinner.component';
import './styles/global.scss';
import './App.scss';
import { Navbar } from './shared/Navbar/Navbar.component';
import HomepageGuard from './core/HomepageGuard';
const Homepage = React.lazy(() => import('./Homepage/Homepage.component'));
const ContactPage = React.lazy(() => import('./ContactPage/ContactPage'));

function App() {
    const [isLoading, setIsloading] = useState<boolean>(true);
    setTimeout(() => {
        setIsloading(false);
    }, 2500);
    return (
        <BrowserRouter>
            <>
                <Navbar />
                <Routes>
                    {/* HOMEPAGE ROUTE */}
                    <Route
                        path="/"
                        element={
                            isLoading ? (
                                <FullPageLoader />
                            ) : (
                                <React.Suspense fallback={<FullPageLoader />}>
                                    <HomepageGuard isLoggedIn={true}>
                                        <Homepage />
                                    </HomepageGuard>
                                </React.Suspense>
                            )
                        }
                    ></Route>
                    {/* CONTACT ROUTE */}
                    <Route
                        path="/contact"
                        element={
                            <React.Suspense fallback={<FullPageLoader />}>
                                <ContactPage />
                            </React.Suspense>
                        }
                    ></Route>
                </Routes>
            </>
        </BrowserRouter>
    );
}

export default App;
