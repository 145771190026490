import React from 'react';
import { PropagateLoader } from 'react-spinners';
import classes from './FullPageSpinner.component.module.scss';

const FullPageLoader: React.FC = () => {
    return (
        <div className={classes.fullPageLoader}>
            <PropagateLoader />
        </div>
    );
};

export default FullPageLoader;
